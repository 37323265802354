.hero-main-container {
  height: 90vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f3f5f9;
}

.hero-main-container .hero-left-box {
  max-width: 550px;
}

.hero-main-container h1 {
  font-family: Poppins;
  font-size: 80px;
  font-style: normal;
  font-weight: 500;
  line-height: 110%;
}

.hero-main-container p {
  font-family: Poppins;
  font-size: 18px;
  font-style: normal;
  font-weight: 400;
  line-height: 160%;
  margin-bottom: 30px;
}


.hero-left-btns-box button {
  border-radius: 50px;
  height: 42px;
}

.hero-main-container .hero-right-box {
  padding: 90px 0 0 100px;
}

.hero-right-box img {
  height: 650px;
}

@media screen and (max-width: 899px) {
  .hero-main-container {
    flex-direction: column;
  }
  .hero-main-container .hero-left-box {
    margin: 0px;
    padding: 20px;
    width: 100%;
  }
  .hero-main-container h1 {
    font-size: 42px;
  }

  .hero-main-container p {
    font-size: 18px;
    margin-bottom: 30px;
  }
  .hero-main-container .hero-right-box {
    padding: 10px;
    margin: 0px;
    width: 100%;
  }
  .hero-right-box {
    display: flex;
    justify-content: center;
  }
  .hero-right-box img {
    display: none;
  }
}
