
.swiper {
    width: 100%;
    padding-top: 50px;
    padding-bottom: 50px;
    margin: 2rem 0;
  }
  
  .swiper-slide {
    background-position: center;
    background-size: cover;
    width: 300px;
    height: 300px;
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 300px;
    border-radius: 5%;
  }
  
  .swiper-slide-shadow-right{
    border-radius: 5%;
  }
  .swiper-slide-shadow-left{
    border-radius: 5%;
  }


  /* Media query for medium-sized screens (md) */
@media (max-width: 900px) {
    .swiper-slide{
        height: 250px;
    }
    .swiper-slide img{
      height: 250px;
    }
  }