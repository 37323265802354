.about-us-box {
  display: flex;
}

.about-container {
  margin-top: 50px;
}

.about-us-title {
  width: 40%;
}

.about-us-quote {
  width: 75%;
  margin: 30px auto;
}

.about-us-content-box{
  margin: auto;
  justify-content: center;
}

.about-us-content-box p {
  margin-bottom: 40px;
  font-size: 1.1rem
}

.about-us-content-box button {
  margin-bottom: 20px;
}

@media screen and (max-width: 899px) {
  .about-us-title {
    width: 100%;
    padding: 10px;
  }
  .about-us-quote {
    width: 100%;
    margin: auto;
    font-size: 12px;
  }
  .about-us-box {
    flex-direction: column;
    padding: 10px;
  }
  .about-us-content-box p {
    margin-bottom: 20px;
  }
  .about-us-content-box button {
    margin-bottom: 10px;
  }
}
