.footer-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 60px;
  background: #fffbf2;
  box-shadow: 0 -0.01rem 0.6rem rgba(0, 0, 0, 0.15);
  margin-top: 3rem;
}

.footer-logo .logo-img {
  width: 150px;
  height: 50px;
}

.footer-menu {
  text-align: center;
}

.footer-menu .menu-item {
  margin: 0 10px;
  text-decoration: none;
  color: #000;
  transition: color 0.3s;
}

.footer-menu .menu-item:hover {
  color: #3167d9;
}

.footer-social {
  text-align: right;
}

.footer-social .social-icon {
  margin: 5px;
  font-size: 20px;
  color: white;
  background-color: #3167d9;
  border-radius: 50%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  transition: background-color 0.3s, color 0.3s;
}

.footer-social .social-icon:hover {
  background-color: white;
  color: #3167d9;
}

.footer-social .design-text {
  font-size: 20px;
  margin-top: 5px;
  font-weight: bold;
}
.footer-social .design-text span {
  font-size: 20px;
  margin-top: 5px;
  font-weight: bold;
  color: black;
}
/* Existing styles... */

@media (max-width: 900px) {
  .footer-container {
    flex-direction: row;
    justify-content: space-between;
    text-align: inherit;
  }

  .footer-menu {
    flex-direction: row;
    justify-content: center;
  }

  .footer-social {
    flex-direction: row;
  }
  .footer-social .design-text {
    font-size: 10px;
    margin-top: 5px;
    font-weight: bold;
  }
  .footer-social .design-text span {
    font-size: 10px;
    margin-top: 5px;
    font-weight: bold;
    color: black;
  }
}
@media (max-width: 750px) {
  .footer-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 60px;
    background-color: #f8f9fa;
  }
  .footer-menu .menu-item {
    font-size: 14px;
  }

  .footer-social .social-icon {
    font-size: 18px;
  }
  .footer-social .design-text {
    font-size: 15px;
    margin-top: 5px;
    font-weight: bold;
  }
  .footer-social .design-text span {
    font-size: 15px;
    margin-top: 5px;
    font-weight: bold;
    color: black;
  }
  .footer-social {
    text-align: center;
  }
}

@media (max-width: 430px) {
  .footer-container {
    padding: 10px;
    background: #fffbf2;
  }
}
@media (max-width: 430px) {
  .footer-container {
    padding: 10px;
  }
}
@media (max-width: 330px) {
  .footer-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0px;
    background: #fffbf2;

    margin-top: 20px;
  }
  .footer-menu .menu-item {
    margin: 0 4px;
    text-decoration: none;
    color: #000;
    transition: color 0.3s;
  }
}
