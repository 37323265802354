.contact-container {
  display: flex;
  margin-top: 3rem;
}

.contact-form {
  background-color: #fff;
  padding: 30px;
  width: 550px;
  height: 670px;
  border-radius: 20px;
  margin-right: -15px;
  z-index: 2;
  /* right: -15px; */
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-form #title {
  color: var(--primary-color, #3167d9);
  text-align: center;
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.contact-form img {
  width: 100px;
  height: 100px;
  margin: auto;
  margin-bottom: 10px;
}

.input-field {
  border-radius: 100px !important;
  background: rgba(243, 241, 236, 0.6) !important;
  padding: 10px 20px !important;
}

.message {
  border-radius: 30px !important;
  background: rgba(243, 241, 236, 0.6) !important;
  padding: 10px 20px !important;
  height: 100px !important;
  overflow: auto;
}

.contact-form button {
  background: var(--primary-color, #3167d9) !important;
  color: #fff !important;
  border-radius: 50px !important;
  margin-top: 20px;
}

.contact-form button:hover {
  background: #fff !important;
  color: #3167d9 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.map-component {
  border-radius: 0 20px 20px 0;
  overflow: hidden;
  width: 100%;
  height: 670px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}

.contact-footer {
  background-color: #fff;
  padding: 30px;
  border-radius: 20px;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  margin-top: 40px;
  margin-bottom: 3rem;
}

.contact-footer #title {
  color: var(--primary-color, #3167d9);
  font-family: Poppins;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: 36px;
}

.contact-box {
  display: flex;
  justify-content: space-around;
}

.contact-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contact-box h2 {
  margin: 0;
  margin-bottom: 5px;
}

.contact-box p {
  font-size: 18px;
  margin: 5px;
}

@media screen and (max-width: 899px) {
  .contact-container {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .contact-form {
    width: 100%;
    margin: auto;
  }
  .contact-form #title {
    font-size: 20px;
    text-align: center;
  }
  .map-component {
    border-radius: 20px;
    width: 100%;
    height: 300px;
    margin-top: 20px;
  }

  .contact-footer {
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    margin-top: 20px;
  }
  .contact-footer #title {
    font-size: 20px;
    text-align: center;
  }

  .contact-box {
    flex-direction: column;
  }

  .contact-info {
    display: flex;
    justify-content: left;
    width: 100%;
  }

  .contact-box h2 {
    font-size: 18px;
  }

  .contact-box p {
    font-size: 14px;
  }
}
