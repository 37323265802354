.menu-btn {
  margin: 0 10px !important;
  color: var(--txt, #291b00) !important;
  font-family: Poppins !important;
  font-size: 1.1rem !important;
  font-style: normal !important;
  font-weight: 400 !important;
  line-height: normal !important;
}
.menu-btn:hover {
  color: var(--txt, #3167d9) !important;
  font-weight: 500 !important;
  background-color: #f3f5f9 !important;
}

.action-btn-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 32px;
}

.action-btn-container button {
  border-radius: 100px !important;
  padding: 8px 32px !important;
  color: var(--primary-color, #3167d9);
  font-family: Poppins;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
}

.menu-contact-no-btn {
  border: 1px solid var(--primary-color, #3167d9) !important;
}

.menu-contact-no-btn svg {
  font-size: 16px;
  margin-right: 10px;
}

.menu-contact-no-btn:hover {
  background: #fff !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
  border: none !important;
}

.menu-appointment-btn {
  background: var(--primary-color, #3167d9) !important;
  color: #fff !important;
}

.menu-appointment-btn:hover {
  background: #fff !important;
  color: #3167d9 !important;
  box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
}
